<template>
  <Sidebar
      title="Создание школы"
      @close="close()">

    <!-- Sidebar content -->
    <template v-slot:content>
      <form autocomplete="off" @submit.prevent="create(form)">
        <SvInput
            class="form-item"
            ref="firstInput"
            label="Название"
            placeholder="Введите название..."
            v-model="form.name"
            :error="error.name"
            @focus="error.name = ''"/>

        <SvInput
            class="form-item"
            label="Город"
            placeholder="Введите город..."
            v-model="form.city"
            :error="error.city"
            @focus="error.city = ''"/>

        <SvInput
            class="form-item"
            label="Адрес"
            placeholder="Введите адрес..."
            v-model="form.addr"
            :error="error.addr"
            @focus="error.addr = ''"/>

        <SvInput
            class="form-item"
            label="Расписание работы"
            placeholder="Введите расписание работы..."
            v-model="form.schedule"
            :error="error.schedule"
            @focus="error.schedule = ''"/>
      </form>
    </template>

    <!-- Sidebar buttons -->
    <template v-slot:buttons>
      <SvButton border primary @click="create(form)">Создать</SvButton>
    </template>
  </Sidebar>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {SvButton, SvInput} from "@/components/default";

import Sidebar from "@/components/sidebar/Sidebar";

export default {
  name: "Create",
  components: {
    SvButton,
    SvInput,
    Sidebar
  },
  emits: [
    "close",
  ],
  data() {
    return {
      form: {
        name: "",
        city: "",
        addr: "",
        schedule: ""
      },
      error: {}
    }
  },
  methods: {
    ...mapActions("schools", [
      "createSchool",
    ]),

    create(data) {
      this.createSchool(data).then(() => {
        this.$message.success("Сохранено успешно")
        this.close()
      }).catch(err => {
        this.error = ParseBackendErr(err)
      })
    },

    close() {
      this.form = {}
      this.error = {}
      this.$emit("close")
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.firstInput.focus()
    })
  }
}
</script>

<style scoped>
</style>