<template>
  <div class="filters">
    <SelectCity
        class="filters-field"
        :value="city"
        @change="$emit('changeCity', $event || '')"
        placeholder="Выберите город"/>

    <SvInput
        class="filters-field search"
        placeholder="Поиск"
        :loading="loading"
        :modelValue="similar"
        @input="$emit('changeSimilar', $event || '')"/>
  </div>
</template>

<script>
import {SvInput} from "@/components/default";

import SelectCity from "@/components/select/City"

export default {
  name: "Filter",
  components: {
    SelectCity,
    SvInput,
  },
  emits: [
    "changeCity",
    "changeSimilar",
  ],
  props: {
    city: {type: String, required: true},
    similar: {type: String, required: true},
    loading: {type: Boolean, default: false},
  },
}
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: flex-end;
  padding-top: 4px;
  padding-bottom: 16px;

  &-field {
    height: 36px;
    margin-right: 12px;

    &.search {
      width: 300px;
    }
  }
}
</style>
