<template>
  <SvSelect
      filter
      clearable
      :model-value="value"
      :label="label"
      :loading="loading"
      :placeholder="placeholder"
      :options="suggests.cities"
      @change="handleChange"/>
</template>

<script>
import API from "@/api";
import {SvSelect} from "@/components/default";

export default {
  name: "City",
  components: {
    SvSelect,
  },
  emits: [
    "change",
  ],
  props: {
    value: {type: String, required: true},
    label: {type: String, default: ""},
    loading: {type: Boolean, default: false},
    placeholder: {type: String, default: "Выберите город"},
  },
  methods: {
    handleChange(evt) {
      this.$emit("change", evt || '')
    },
  },
  data() {
    return {
      suggests: {
        cities: []
      },
    }
  },
  created() {
    API.schools.suggest("city").then((resp) => {
      this.suggests.cities = resp.data.list || []
    })
  }
}
</script>

<style scoped>
</style>