<template>
  <Sidebar
      title="Редактирование школы"
      @close="close()">

    <!-- Sidebar content -->
    <template v-slot:content>
      <form autocomplete="off" @submit.prevent="update(form)">
        <SvInput
            class="form-item"
            label="Название"
            placeholder="Введите название..."
            v-model="form.name"
            :error="error.name"
            @focus="error.name = ''"/>

        <SvInput
            class="form-item"
            label="Город"
            placeholder="Введите город..."
            v-model="form.city"
            :error="error.city"
            @focus="error.city = ''"/>

        <SvInput
            class="form-item"
            label="Адрес"
            placeholder="Введите адрес..."
            v-model="form.addr"
            :error="error.addr"
            @focus="error.addr = ''"/>

        <SvInput
            class="form-item"
            label="Расписание работы"
            placeholder="Введите расписание работы..."
            v-model="form.schedule"
            :error="error.schedule"
            @focus="error.schedule = ''"/>
      </form>
    </template>

    <!-- Sidebar buttons -->
    <template v-slot:buttons>
      <SvButton border primary @click="update(form)">Сохранить</SvButton>
    </template>
  </Sidebar>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {SvButton, SvInput} from "@/components/default"

import Sidebar from "@/components/sidebar/Sidebar";

export default {
  name: "Update",
  components: {
    SvButton,
    SvInput,
    Sidebar,
  },
  emits: [
    "close",
  ],
  props: {
    data: {type: Object, required: true}
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.data)),
      error: {}
    }
  },
  methods: {
    ...mapActions("schools", [
      "updateSchool",
    ]),

    update(data) {
      this.updateSchool(data).then(() => {
        this.$message.success("Сохранено успешно")
        this.close()
      }).catch((err) => {
        this.error = ParseBackendErr(err)
      })
    },

    close() {
      this.form = {}
      this.error = {}
      this.$emit("close")
    },
  },
}
</script>

<style scoped>
</style>
