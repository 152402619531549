<template>
  <div class="page">
    <div class="page-header">
      <h3 class="page-title">Школы</h3>
      <div class="button">
        <SvButton primary @click="activeForm ='create'">Создать</SvButton>
      </div>
    </div>

    <Filters
        :city="filter.city"
        :similar="filter.similar"
        :loading="loading"
        @changeCity="filterBy('city', $event)"
        @changeSimilar="filterBy('similar', $event)"/>

    <div class="page-data">
      <Table
          :items="schools"
          :fields="tableFields"
          :loading="loading"
          @rightClick="startUpdate"/>

      <Create
          v-if="activeForm === 'create'"
          @close="close()"/>

      <Update
          v-else-if="activeForm === 'update'"
          :data="selected"
          @close="close()"/>
    </div>

    <SvScrollTrigger @triggered="fetchNextSchools"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {SvButton, SvScrollTrigger} from "@/components/default";

import Table from '@/components/table/Table';

import Filters from '@/pages/schools/filters/Filters';
import Create from '@/pages/schools/forms/Create';
import Update from '@/pages/schools/forms/Update';

export default {
  name: "Schools",
  components: {
    SvButton,
    SvScrollTrigger,
    Table,
    Filters,
    Create,
    Update
  },
  data() {
    return {
      tableFields: [
        {
          header: true,
          prop: "name",
          label: "Название",
        },
        {
          prop: "city",
          label: "Город",
        },
        {
          prop: "addr",
          label: "Адрес",
        },
        {
          prop: "schedule",
          label: "Расписание работы",
        },
      ],
      activeForm: ""
    }
  },
  methods: {
    ...mapActions("schools", [
      "fetchSchools",
      "fetchNextSchools",
      "filterSchools",
      "findSchool",
      "resetState",
    ]),

    filterBy(key, value) {
      this.filterSchools({key: key, value: value})
    },

    async startUpdate(schoolID) {
      await this.findSchool(schoolID).then(() => {
        this.activeForm = "update"
      })

      if (this.$route.query.id !== schoolID) {
        await this.$router.push(`/schools?id=${schoolID}`)
      }
    },

    close() {
      if (this.$route.query.id) {
        this.$router.push(`/schools`)
      }

      this.activeForm = ""
    }
  },
  computed: {
    ...mapGetters("schools", [
      "schools",
      "selected",
      "filter",
      "loading",
    ]),
  },
  created() {
    this.fetchSchools().catch(err => {
      console.error(err)
    })

    if (this.$route.query.id) {
      this.startUpdate(Number(this.$route.query.id))
    }
  },
  unmounted() {
    this.resetState()
  }
}
</script>

<style lang="scss" scoped>
.button {
  margin: auto 0;
}
</style>
